<template>
	<div class="data-content">
		<div>
			<el-form ref="form" :model="form" :rules="rules" label-position="top">
				<div class="form-section">
					<h3> 维修单 </h3>
					<el-divider class="mt0"></el-divider>
					<div class="section-content">
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="流水号" prop="serialNumber"> {{
										form.serialNumber || "--"
									}}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="维修区分" prop="repairType">
									<el-select v-model="form.repairType" placeholder="请选择" class="full-width">
										<el-option v-for="option in repairTypeOptions"
												   :key="option.id"
												   :label="option.name"
												   :value="option.id"
										></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="特约维修店名" prop="dealerName"> {{
										form.dealerName || "--"
									}}
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="6">
								<el-form-item label="机号" prop="machineNo">
									<el-input v-model="form.machineNo" placeholder="请输入"
											  @input="onMachineNoInput"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="商品编号"> {{ form.productCode || "--" }}</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="机型编号"> {{ form.modelCode || "--" }}</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="机型" prop="modelName">
									<el-input v-model="form.modelName" placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="出库日期"> {{ form.outDate || "--" }}</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="保修期结束日"> {{ form.warrantyEndDate || "--" }}</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="保修期"> {{ warrantyPeriodText }}</el-form-item>
							</el-col>
							<el-col :span="6">
								<el-form-item label="PPS号"> {{ form.ppsNo || "--" }}</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="是否需要上传发票" prop="invoiceRequired">
									<el-radio-group v-model="form.invoiceRequired" :disabled="invoiceForceRequired && auditStatus !== repairAuditStatusEnum.REPAIR_APPROVAL_RETURNED">
										<el-radio :label="1">
											<el-tag type="primary">是</el-tag>
										</el-radio>
										<el-radio :label="0">
											<el-tag type="success">否</el-tag>
										</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
							<template v-if="form.invoiceRequired">
								<el-col :span="8">
									<el-form-item label="销售日期" prop="repairInvoice.saleDate"
												  :rules="form.invoiceRequired ? repairInvoiceRules.saleDate : null">
										<el-date-picker
											v-model="form.repairInvoice.saleDate"
											type="datetime"
											value-format="yyyy-MM-dd HH:mm:ss"
											placeholder="请选择"
											class="full-width"
											@change="onSaleDateChanged"
											:disabled="invoiceForceRequired  && auditStatus !== repairAuditStatusEnum.REPAIR_APPROVAL_RETURNED"
										></el-date-picker>
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="发票附件" prop="repairInvoice.invoiceFile"
												  :rules="form.invoiceRequired ? repairInvoiceRules.invoiceFile : null">
										<el-upload
											:action="action"
											:headers="headers"
											:multiple="false"
											:show-file-list="false"
											:before-upload="handleBeforeUpload"
											:on-success="handleFileUploaded"
											:disabled="invoiceForceRequired && auditStatus !== repairAuditStatusEnum.REPAIR_APPROVAL_RETURNED"
										>
											<el-button icon="el-icon-paperclip" :disabled="invoiceForceRequired && auditStatus !== repairAuditStatusEnum.REPAIR_APPROVAL_RETURNED">
												上传文件
											</el-button>
										</el-upload>
										<FileList v-if="!(invoiceForceRequired && auditStatus !== repairAuditStatusEnum.REPAIR_APPROVAL_RETURNED)" :file-list="invoiceFileList" closable
												  @onRemove="handleFileRemove" class="mt4"></FileList>
										<FileList v-else :file-list="invoiceFileList" @onRemove="handleFileRemove"
												  class="mt4"></FileList>
									</el-form-item>
								</el-col>
							</template>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="维修受理日期" prop="repairAcceptDate">
									<el-date-picker
										v-model="form.repairAcceptDate"
										type="date"
										value-format="yyyy-MM-dd"
										placeholder="请选择"
										class="full-width"
									></el-date-picker>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="出发时间" prop="departureTime">
									<el-date-picker
										v-model="form.departureTime"
										type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss"
										placeholder="请选择"
										class="full-width"
									></el-date-picker>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="到达时间（维修开始时间）" prop="arrivalTime">
									<el-date-picker
										v-model="form.arrivalTime"
										type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss"
										placeholder="请选择"
										class="full-width"
									></el-date-picker>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="服务形式" prop="serviceType">
									<el-select v-model="form.serviceType" placeholder="请选择" class="full-width">
										<el-option
											v-for="option in serviceTypeOptions"
											:key="option.id"
											:label="option.name"
											:value="option.id"
										></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="维修合同形态" prop="repairContractType">
									<el-select v-model="form.repairContractType" placeholder="请选择"
											   class="full-width">
										<el-option
											v-for="option in repairContractTypeOptions"
											:key="option.id"
											:label="option.name"
											:value="option.id"
										></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="维修作业项目" prop="repairItems">
									<el-select v-model="form.repairItems" placeholder="请选择" class="full-width">
										<el-option
											v-for="option in repairItemOptions"
											:key="option.id"
											:label="option.name"
											:value="option.id"
										></el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="黑白计数器读数/投影机使用时间" prop="bwCounter">
									<el-input v-model="form.bwCounter" type="number" placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="彩色计数器读数/灯泡使用时间" prop="colorCounter">
									<el-input v-model="form.colorCounter" type="number" placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="制版计数器读数" prop="plateCounter">
									<el-input v-model="form.plateCounter" type="number" placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="是否投影仪" prop="isProjector">
									<el-radio-group v-model="form.isProjector">
										<el-radio :label="1">
											<el-tag type="primary">是</el-tag>
										</el-radio>
										<el-radio :label="0">
											<el-tag type="success">否</el-tag>
										</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
							<template v-if="form.isProjector">
								<el-col :span="8">
									<el-form-item label="使用时间" prop="repairProjector.usageHours"
												  :rules="form.isProjector ? repairProjectorRules.usageHours : null">
										<el-input v-model="form.repairProjector.usageHours"
												  placeholder="请输入"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="除尘频率" prop="repairProjector.dustFrequency"
												  :rules="form.isProjector ? repairProjectorRules.dustFrequency : null">
										<el-input v-model="form.repairProjector.dustFrequency"
												  placeholder="请输入"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="油烟环境" prop="repairProjector.smokeEnvironment"
												  :rules="form.isProjector ? repairProjectorRules.smokeEnvironment : null">
										<el-input v-model="form.repairProjector.smokeEnvironment"
												  placeholder="请输入"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="箱体环境" prop="repairProjector.caseEnvironment"
												  :rules="form.isProjector ? repairProjectorRules.caseEnvironment : null">
										<el-input v-model="form.repairProjector.caseEnvironment"
												  placeholder="请输入"></el-input>
									</el-form-item>
								</el-col>
								<el-col :span="8">
									<el-form-item label="其他备注" prop="repairProjector.remarks">
										<el-input v-model="form.repairProjector.remarks" type="textarea"
												  placeholder="请输入"></el-input>
									</el-form-item>
								</el-col>
							</template>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="服务费已支付" prop="serviceFeePaid">
									<el-input v-model="form.serviceFeePaid" placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="服务费支付公司" prop="paymentCompany">
									<el-input v-model="form.paymentCompany" placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="服务费支付日期" prop="paymentDate">
									<el-date-picker
										v-model="form.paymentDate"
										type="date"
										value-format="yyyy-MM-dd"
										placeholder="请选择"
										class="full-width"
									></el-date-picker>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="12">
								<el-form-item label="预计维修完成时间" prop="expectedCompletionTime">
									<el-date-picker
										v-model="form.expectedCompletionTime"
										type="datetime"
										value-format="yyyy-MM-dd HH:mm:ss"
										placeholder="请选择"
										class="full-width"
									></el-date-picker>
								</el-form-item>
							</el-col>
							<el-col :span="12">
								<el-form-item label="区域" prop="region">
									<el-radio-group v-model="form.region">
										<el-radio label="1">
											<el-tag type="primary">华东</el-tag>
										</el-radio>
										<el-radio label="2">
											<el-tag type="success">华南</el-tag>
										</el-radio>
										<el-radio label="3">
											<el-tag type="warning">华北</el-tag>
										</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="受理时随机附件以及送修商品外观备注" prop="receptionAttachments">
									<el-input v-model="form.receptionAttachments" type="textarea"
											  placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="用户公司" prop="customerCompany">
									<el-input v-model="form.customerCompany" placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="客户代码" prop="customerCode">
									<el-input v-model="form.customerCode" placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="邮政编码" prop="postalCode">
									<el-input v-model="form.postalCode" placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="送修人/保修人" prop="contactPerson">
									<el-input v-model="form.contactPerson" placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="联系电话" prop="contactPhone">
									<el-input v-model="form.contactPhone" type="text" :maxlength="11"
											  placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="结算状态"> {{ form.settlementStatus || "--" }}</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="地址" prop="district">
									<el-cascader
										:options="provinceData"
										:props="provinceProps"
										show-all-levels
										v-model="address"
										clearable
										placeholder="请选择"
										class="full-width">
									</el-cascader>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="详细地址" prop="addressDetail">
									<el-input v-model="form.addressDetail" type="textarea"
											  placeholder="请输入详细地址"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="8">
								<el-form-item label="客户应付维修费">
									{{ formatPriceString(form.customerRepairFee) || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="客户应付上门费">
									{{ formatPriceString(form.customerVisitFee) || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="客户应付零件费">
									{{ formatPriceString(form.customerPartsFee) || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="客户应付耗材费">
									{{ formatPriceString(form.customerSuppliesFee) || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="客户应付其他费用" prop="customerOtherFee">
									<el-input v-model="form.customerOtherFee" type="number" placeholder="请输入">
										<template slot="append">元</template>
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="客户其它费用说明" prop="customerOtherFeeNotes">
									<el-input v-model="form.customerOtherFeeNotes" placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="特约维修服务费">
									{{ formatPriceString(form.dealerServiceFee) || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="委托维修服务费">
									{{ formatPriceString(form.entrustServiceFee) || "--" }}
								</el-form-item>
							</el-col>
							<el-col :span="8">
								<el-form-item label="其他费用" prop="otherFees">
									<el-input v-model="form.otherFees" type="number" placeholder="请输入">
										<template slot="append">元</template>
									</el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="备注" prop="remarks">
									<el-input v-model="form.remarks" type="textarea" placeholder="请输入"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="form-section">
					<h3> 维修结果 </h3>
					<el-divider class="mt0"></el-divider>
					<div class="section-content"></div>
				</div>
				<div class="form-section">
					<h3> 故障 </h3>
					<el-divider class="mt0"></el-divider>

					<div class="section-content">
						<template v-if="faultPhen">
							<div class="section-title"> 故障现象</div>
							<table class="form-table">
								<thead>
								<tr>
									<th class="col-required"> 分类</th>
									<th class="col-required"> 现象</th>
									<th> 详细说明</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>
										<el-form-item>
											<el-select v-model="faultPhen.category" placeholder="请选择"
													   class="full-width">
												<el-option
													v-for="option in faultPhenTypeOptions"
													:key="option.id"
													:label="option.name"
													:value="option.id"
												></el-option>
											</el-select>
										</el-form-item>
									</td>
									<td>
										<el-form-item>
											<el-select v-model="faultPhen.categoryDetail" placeholder="请选择"
													   class="full-width">
												<el-option
													v-for="option in faultPhenOptions"
													:key="option.id"
													:label="option.name"
													:value="option.id"
												></el-option>
											</el-select>
										</el-form-item>
									</td>
									<td>
										<el-form-item>
											<el-input v-model="faultPhen.remark" type="textarea"
													  placeholder="请输入"></el-input>
										</el-form-item>
									</td>
								</tr>
								</tbody>
							</table>
						</template>

						<template v-if="faultCause">
							<div class="section-title"> 故障原因</div>
							<table class="form-table">
								<thead>
								<tr>
									<th class="col-required"> 分类</th>
									<th class="col-required"> 原因</th>
									<th> 详细说明</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>
										<el-form-item>
											<el-select v-model="faultCause.category" placeholder="请选择"
													   class="full-width">
												<el-option
													v-for="option in faultCauseTypeOptions"
													:key="option.id"
													:label="option.name"
													:value="option.id"
												></el-option>
											</el-select>
										</el-form-item>
									</td>
									<td>
										<el-form-item>
											<el-select v-model="faultCause.categoryDetail" placeholder="请选择"
													   class="full-width">
												<el-option
													v-for="option in faultCauseOptions"
													:key="option.id"
													:label="option.name"
													:value="option.id"
												></el-option>
											</el-select>
										</el-form-item>
									</td>
									<td>
										<el-form-item>
											<el-input v-model="faultCause.remark" type="textarea"
													  placeholder="请输入"></el-input>
										</el-form-item>
									</td>
								</tr>
								</tbody>
							</table>
						</template>

						<template v-if="faultMeasures">
							<div class="section-title"> 故障措施</div>
							<table class="form-table">
								<thead>
								<tr>
									<th class="col-required"> 分类</th>
									<th class="col-required"> 措施</th>
									<th> 详细说明</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>
										<el-form-item>
											<el-select v-model="faultMeasures.category" placeholder="请选择"
													   class="full-width">
												<el-option
													v-for="option in faultMeasuresTypeOptions"
													:key="option.id"
													:label="option.name"
													:value="option.id"
												></el-option>
											</el-select>
										</el-form-item>
									</td>
									<td>
										<el-form-item>
											<el-select v-model="faultMeasures.categoryDetail" placeholder="请选择"
													   class="full-width">
												<el-option
													v-for="option in faultMeasuresOptions"
													:key="option.id"
													:label="option.name"
													:value="option.id"
												></el-option>
											</el-select>
										</el-form-item>
									</td>
									<td>
										<el-form-item>
											<el-input v-model="faultMeasures.remark" type="textarea"
													  placeholder="请输入"></el-input>
										</el-form-item>
									</td>
								</tr>
								</tbody>
							</table>
						</template>

						<template v-if="faultPosition">
							<div class="section-title"> 故障部位</div>
							<table class="form-table">
								<thead>
								<tr>
									<th class="col-required"> 分类</th>
									<th class="col-required"> 部位</th>
									<th> 详细说明</th>
								</tr>
								</thead>
								<tbody>
								<tr>
									<td>
										<el-form-item>
											<el-select v-model="faultPosition.category" placeholder="请选择"
													   class="full-width">
												<el-option
													v-for="option in faultPositionTypeOptions"
													:key="option.id"
													:label="option.name"
													:value="option.id"
												></el-option>
											</el-select>
										</el-form-item>
									</td>
									<td>
										<el-form-item>
											<el-select v-model="faultPosition.categoryDetail" placeholder="请选择"
													   class="full-width">
												<el-option
													v-for="option in faultPositionOptions"
													:key="option.id"
													:label="option.name"
													:value="option.id"
												></el-option>
											</el-select>
										</el-form-item>
									</td>
									<td>
										<el-form-item>
											<el-input v-model="faultPosition.remark" type="textarea"
													  placeholder="请输入"></el-input>
										</el-form-item>
									</td>
								</tr>
								</tbody>
							</table>
						</template>
					</div>
				</div>
				<div class="form-section">
					<h3> 主机/零件/选购件或耗材 </h3>
					<el-divider class="mt0"></el-divider>

					<div class="section-content">
						<el-row :gutter="20">
							<el-col :span="24">
								<el-form-item label="是否需要主机/零件/选购件或耗材">
									<el-radio-group v-model="form.hasMainParts">
										<el-radio :label="1">
											<el-tag type="primary">主机</el-tag>
										</el-radio>
										<el-radio :label="2">
											<el-tag type="success">零件</el-tag>
										</el-radio>
										<el-radio :label="3">
											<el-tag type="warning">不需要</el-tag>
										</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
							<el-col v-if="form.hasMainParts === 2" :span="24">
								<el-form-item label="是否可以在零件未到货的情况下完成维修">
									<el-radio-group v-model="form.canRepairWithoutParts">
										<el-radio :label="1">
											<el-tag type="primary">可以</el-tag>
										</el-radio>
										<el-radio :label="0">
											<el-tag type="success">不可以</el-tag>
										</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row v-if="form.hasMainParts === 1 || form.hasMainParts === 2" :gutter="20">
							<el-col :span="24">
								<el-form-item label="主机/零件/选购件或耗材" class="mb0"></el-form-item>
								<table class="form-table fixed-table">
									<thead>
									<tr style="font-size: 15px;">
										<th> 序号</th>
										<th class="col-required"> 故障零件分类</th>
										<th class="col-required"> 零件/耗材编号</th>
										<th class="col-required"> 零件/耗材名称</th>
										<th class="col-required"> 零件/耗材数量</th>
										<th> 故障零件序列号码</th>
										<th class="col-required"> 是否申请</th>
										<th> 操作</th>
									</tr>
									</thead>
									<tbody>
									<tr v-for="(item, i) in form.mainPartsList">
										<td class="text-center"> {{ i + 1 }}</td>
										<td>
											<el-form-item class="mb0">
												<el-select v-model="form.mainPartsList[i].faultyPartCategory"
														   placeholder="请选择" class="full-width">
													<el-option
														v-for="option in faultyPartCategoryOptions"
														:key="option.id"
														:label="option.name"
														:value="option.id"
													></el-option>
												</el-select>
											</el-form-item>
										</td>
										<td>
											<el-form-item class="mb0">
												<el-input v-model="form.mainPartsList[i].partConsumableNumber"
														  placeholder="请输入"></el-input>
											</el-form-item>
										</td>
										<td>
											<el-form-item class="mb0">
												<el-input v-model="form.mainPartsList[i].partConsumableName"
														  placeholder="请输入"></el-input>
											</el-form-item>
										</td>
										<td>
											<el-form-item class="mb0">
												<el-input v-model="form.mainPartsList[i].partConsumableQuantity"
														  placeholder="请输入"></el-input>
											</el-form-item>
										</td>
										<td>
											<el-form-item class="mb0">
												<el-input v-model="form.mainPartsList[i].faultyPartSerialNumber"
														  placeholder="请输入"></el-input>
											</el-form-item>
										</td>
										<td>
											<el-form-item class="mb0">
												<el-select v-model="form.mainPartsList[i].applicationStatus"
														   placeholder="请选择" class="full-width">
													<el-option
														v-for="option in applicationStatusOptions"
														:key="option.id"
														:label="option.name"
														:value="option.id"
													></el-option>
												</el-select>
											</el-form-item>
										</td>
										<td>
											<el-button type="text" class="btn-red" @click="handleRemoveMainPart(i)">
												删除
											</el-button>
										</td>
									</tr>
									</tbody>
								</table>
								<div>
									<el-button icon="el-icon-circle-plus-outline" @click="handleAddMainPart">新增一项
									</el-button>
								</div>
							</el-col>
						</el-row>
					</div>
				</div>
				<div class="form-section">
					<h3> 附件 </h3>
					<div class="ml20 notice-subtitle"> 特约维修零件索赔：故障零件照片、故障照片、发票、保卡</div>
					<div class="ml20 mt5 notice-subtitle">
						特约维修主机索赔：主机整体照片、主机机号照片、故障照片、发票、保卡
					</div>
					<el-divider class="mt20"></el-divider>
					<div class="section-content">
						<!--<div class="notice-text">上传单个附件不能超过2M!</div>-->
						<div>
							<el-form-item label="维修单附件" prop="repairInvoice.invoiceFile">
								<el-upload
									:action="action"
									:headers="headers"
									:multiple="true"
									:show-file-list="false"
									:before-upload="handleBeforeAttachFileUpload"
									:on-success="handleAttachFileUploaded"
								>
									<el-button icon="el-icon-paperclip">上传文件</el-button>
								</el-upload>
								<FileList :file-list="attachFileList" closable @onRemove="handleAttachFileRemove"
										  class="mt4"></FileList>
							</el-form-item>
						</div>
					</div>
				</div>
				<div class="form-section">
					<h3> CS维修单回访 </h3>
					<el-divider class="mt0"></el-divider>
					<div class="section-content"></div>
				</div>
			</el-form>
			<div class="fixed-wrapper">
				<el-button type="warning" @click="saveTemporary">暂存</el-button>
			</div>
		</div>
		<div>
			<el-divider></el-divider>
			<el-button type="primary" @click="submit">提交</el-button>
		</div>

	</div>
</template>

<script>
import {debounce} from '@/utils/debounce'
import defaultSettings from "@/settings"
import {
	repairTypeOptions,
	warrantyPeriodOptions,
	serviceTypeOptions,
	repairContractTypeOptions,
	repairItemOptions,
	faultyPartCategoryOptions, repairAuditStatusOptions,
} from "@/constant/options"
import FileList from "./components/FileList.vue"
import { repairAuditStatusEnum } from "@/constant/enum"

const url = defaultSettings.hostName

export default {
	components: {
		FileList
	},
	data() {
		return {
			dataId: "",
			form: {
				serialNumber: "",               // 流水号
				repairType: "",                 // 维修区分 1、建议换货 2、维修
				dealerName: "",                 // 特约维修店名
				machineNo: "",                  // 机号
				productCode: "",                // 商品编号
				modelCode: "",                  // 机型编号
				modelName: "",                  // 机型
				outDate: "",                    // 出库日期
				warrantyEndDate: "",            // 保修期结束日
				warrantyPeriod: "",             // 1、保修中  0、非保修中
				ppsNo: "",                      // PPS号
				invoiceRequired: "",            // 是否需要上传发票 0否 1是
				repairInvoice: {
					saleDate: "",                // 销售日期
					invoiceFile: ""             // 发票附件
				},
				repairAcceptDate: "",           // 维修受理日期
				departureTime: "",              // 出发时间
				arrivalTime: "",                // 到达时间（维修开始时间）
				serviceType: "",                // 服务形式 1、送修 2、寄修 3、现场
				repairContractType: "",         // 维修合同形态 1、随时 2、维修合同 3、MC
				repairItems: "",                // 维修作业项目  维修作业项目 1、定期检查  2、修理 3、定期修理 4、继续作业 5、更换零件 6、送货 7、说明 8、合同签约 9、安装机器 10、搬回修理
				bwCounter: "",                  // 黑白计数器读数/投影机使用时间
				colorCounter: "",               // 彩色计数器读数/灯泡使用时间
				plateCounter: "",               // 制版计数器读数
				isProjector: "",                // 是否投影仪 0否 1是
				repairProjector: {
					usageHours: "",             // 使用时间
					dustFrequency: "",          // 除尘频率
					smokeEnvironment: "",       // 油烟环境
					caseEnvironment: "",        // 箱体环境
					remarks: ""                 // 其他备注
				},
				serviceFeePaid: "",             // 服务费已支付
				paymentCompany: "",             // 服务费支付公司
				paymentDate: "",                // 服务费支付日期
				expectedCompletionTime: "",     // 预计维修完成时间
				region: "",                     // 区域  1、华东 2、华南 3、华北
				receptionAttachments: "",       // 受理时随机附件 以及 送修商品外观备注
				customerCompany: "",            // 用户公司
				customerCode: "",               // 客户代码
				postalCode: "",                 // 邮政编码
				contactPerson: "",              // 送修人/保修人
				contactPhone: "",               // 联系电话
				settlementStatus: "",           // 结算状态
				province: "",                   // 省
				city: "",                       // 市
				district: "",                   // 区县
				addressDetail: "",              // 详细地址
				customerRepairFee: "",          // 客户应付维修费
				customerVisitFee: "",           // 客户应付上门费
				customerPartsFee: "",           // 客户应付零件费
				customerSuppliesFee: "",        // 客户应付耗材费
				customerOtherFee: "",           // 客户应付其他费用
				customerOtherFeeNotes: "",      // 客户其它费用说明
				dealerServiceFee: "",           // 特约维修服务费
				entrustServiceFee: "",          // 委托维修服务费
				otherFees: "",                  // 其他费用
				remarks: "",                    // 备注
				faultList: [
					{
						faultType: 1,           // 故障类型 1:故障现象
						category: "",           // 分类
						categoryDetail: "",     // 现象/原因/措施/部位
						remark: ""              // 详细说明
					},
					{
						faultType: 2,           // 故障类型 2:故障原因
						category: "",           // 分类
						categoryDetail: "",     // 现象/原因/措施/部位
						remark: ""              // 详细说明
					},
					{
						faultType: 3,           // 故障类型 3:故障措施
						category: "",           // 分类
						categoryDetail: "",     // 现象/原因/措施/部位
						remark: ""              // 详细说明
					},
					{
						faultType: 4,           // 故障类型 4:故障部位
						category: "",           // 分类
						categoryDetail: "",     // 现象/原因/措施/部位
						remark: ""              // 详细说明
					}
				],
				hasMainParts: "",               // 是否有主机/零件/选购件或耗材   1、有主机  2、有零件 3、没有
				canRepairWithoutParts: "",      // 是否可以在零件未到货的情况下完成维修
				mainPartsList: [],              // 主机/零件/选购件或耗材数组, 结构为mainPartStructure
				fileIdList: [],                 // 附件文件id数组
			},
			mainPartStructure: {
				faultyPartCategory: "",         // 故障零件分类  1、主机   2、零件
				partConsumableNumber: "",       // 零件/耗材编号
				partConsumableName: "",         // 零件/耗材名称
				partConsumableQuantity: "",     // 零件/耗材数量
				faultyPartSerialNumber: "",     // 故障零件序列号码
				applicationStatus: ""           // 是否申请 0否 1是
			},
			repairTypeOptions,
			warrantyPeriodOptions,
			serviceTypeOptions,
			repairContractTypeOptions,
			repairItemOptions,
			faultyPartCategoryOptions,
			applicationStatusOptions: [
				{id: 1, name: "是"},
				{id: 0, name: "否"},
			],
			provinceData: [],                   // 省市区数据
			address: [],
			// 地址选项
			provinceProps: {
				multiple: false,
				emitPath: true,
				value: 'id',
				label: 'name',
				children: 'children',
				disabled: 'disabled'
			},
			faultDictData: {},                  // 故障相关字典数据
			warrantyDays: 0,                    // 保修天数
			_warrantyEndDate: "",               // 保修期结束日
			_warrantyPeriod: "",                // 是否在保修中 1、保修中  0、已过保
			invoiceFileList: [],                // 发票附件文件列表
			attachFileList: [],                 // 附件文件列表
			invoiceForceRequired: false,        // 获取机号接口的数据里,如果存在发票相关数据,则是否需要发票强制设置为[是]且不可变更
            auditStatus: null,                  // 当前的审核状态
            repairAuditStatusEnum,
			rules: {
				repairType: [
					{required: true, message: '维修区分是必填字段', trigger: 'change'},
				],
				machineNo: [
					{required: true, message: '机号是必填字段', trigger: 'blur'},
				],
				modelName: [
					{required: true, message: '机型是必填字段', trigger: 'blur'},
				],
				invoiceRequired: [
					{required: true, message: '是否需要上传发票是必填字段', trigger: 'change'},
				],
				repairAcceptDate: [
					{required: true, message: '维修受理日期是必填字段', trigger: 'change'},
				],
				arrivalTime: [
					{required: true, message: '到达时间（维修开始时间）是必填字段', trigger: 'change'},
				],
				serviceType: [
					{required: true, message: '服务形式是必填字段', trigger: 'change'},
				],
				repairContractType: [
					{required: true, message: '维修合同形态是必填字段', trigger: 'change'},
				],
				repairItems: [
					{required: true, message: '维修作业项目是必填字段', trigger: 'change'},
				],
				bwCounter: [
					{required: true, message: '黑白计数器读数/投影机使用时间是必填字段', trigger: 'blur'},
				],
				isProjector: [
					{required: true, message: '是否投影仪是必填字段', trigger: 'change'},
				],
				expectedCompletionTime: [
					{required: true, message: '预计维修完成时间是必填字段', trigger: 'change'},
				],
				region: [
					{required: true, message: '区域是必填字段', trigger: 'change'},
				],
				customerCompany: [
					{required: true, message: '用户公司是必填字段', trigger: 'blur'},
				],
				contactPerson: [
					{required: true, message: '送修人/保修人是必填字段', trigger: 'blur'},
				],
				contactPhone: [
					{required: true, pattern: /^1\d{10}$/, message: '联系电话格式错误', trigger: 'blur'}
				],
				district: [
					{required: true, message: '地址是必填字段', trigger: 'blur'},
				],
				addressDetail: [
					{required: true, message: '详细地址是必填字段', trigger: 'blur'},
				],
				hasMainParts: [
					{required: true, message: '是否有主机/零件/选购件或耗材是必填字段', trigger: 'change'},
				],
				canRepairWithoutParts: [
					{required: true, message: '是否可以在零件未到货的情况下完成维修是必填字段', trigger: 'change'},
				],
			},
			repairInvoiceRules: {
				saleDate: [
					{required: true, message: '销售日期是必填字段', trigger: 'change'},
				],
				invoiceFile: [
					{required: true, message: '发票附件是必填字段', trigger: 'change'},
				]
			},
			repairProjectorRules: {
				usageHours: [
					{required: true, message: '使用时间是必填字段', trigger: 'blur'},
				],
				dustFrequency: [
					{required: true, message: '除尘频率是必填字段', trigger: 'blur'},
				],
				smokeEnvironment: [
					{required: true, message: '油烟环境是必填字段', trigger: 'blur'},
				],
				caseEnvironment: [
					{required: true, message: '箱体环境是必填字段', trigger: 'blur'},
				],
			},
		}
	},
	created() {
		if (this.$route.query.id) {
			this.getRepairData()
		} else {
			this.initForm()
		}
		this.getProvinceData()
		this.getFaultDictData()
	},
	computed: {
		formInvoiceRequired() {
			return this.form.invoiceRequired
		},
		formHasMainParts() {
			return this.form.hasMainParts
		},
		isOutOfWarranty() {
			// 是否已过保
			return this.form.warrantyPeriod === "0" || this.form.warrantyPeriod === 0
		},
		warrantyPeriodText() {
			if (typeof this.form.warrantyPeriod === "string" && this.form.warrantyPeriod.trim() === "") return "--"
			return this.getWarrantyPeriodText(this.form.warrantyPeriod)
		},
		action() {
			return url + "bsi/sysAttachFile/addRepairFileOss"
		},
		headers() {
			const token = sessionStorage.getItem('token') || ''
			return {
				token: token
			}
		},
		faultPhen() {
			// 故障现象
			for (const item of this.form.faultList) {
				if (item.faultType === 1) {
					return item
				}
			}
		},
		faultPhenTypeOptions() {
			// 特约维修故障现象分类
			if (!this.faultDictData?.authRepFaultPhenType) return []
			return this.faultDictData?.authRepFaultPhenType
		},
		faultPhenOptions() {
			// 特约维修故障现象
			if (!this.faultDictData?.authRepFaultPhen) return []
			return this.faultDictData?.authRepFaultPhen
		},
		faultCause() {
			// 故障原因
			for (const item of this.form.faultList) {
				if (item.faultType === 2) {
					return item
				}
			}
		},
		faultCauseTypeOptions() {
			// 特约维修故障原因分类
			if (!this.faultDictData?.authRepFaultCauseType) return []
			return this.faultDictData?.authRepFaultCauseType
		},
		faultCauseOptions() {
			// 特约维修故障原因
			if (!this.faultDictData?.authRepFaultCause) return []
			return this.faultDictData?.authRepFaultCause
		},
		faultMeasures() {
			// 故障措施
			for (const item of this.form.faultList) {
				if (item.faultType === 3) {
					return item
				}
			}
		},
		faultMeasuresTypeOptions() {
			// 特约维修故障措施分类
			if (!this.faultDictData?.authRepFaultMeasuresType) return []
			return this.faultDictData?.authRepFaultMeasuresType
		},
		faultMeasuresOptions() {
			// 特约维修故障措施
			if (!this.faultDictData?.authRepFaultMeasures) return []
			return this.faultDictData?.authRepFaultMeasures
		},
		faultPosition() {
			// 故障部位
			for (const item of this.form.faultList) {
				if (item.faultType === 4) {
					return item
				}
			}
		},
		faultPositionTypeOptions() {
			// 特约维修故障部位分类
			if (!this.faultDictData?.authRepFaultPositionType) return []
			return this.faultDictData?.authRepFaultPositionType
		},
		faultPositionOptions() {
			// 特约维修故障部位
			if (!this.faultDictData?.authRepFaultPosition) return []
			return this.faultDictData?.authRepFaultPosition
		},
	},
	watch: {
		formInvoiceRequired(newVal, oldVal) {
			if (!newVal) {
				this.form.repairInvoice.saleDate = ""
				this.form.repairInvoice.invoiceFile = ""
			}
		},
		address(newVal, oldVal) {
			if (Array.isArray(newVal) && newVal.length === 3) {
				this.form.province = newVal[0].toString()
				this.form.city = newVal[1].toString()
				this.form.district = newVal[2].toString()
			}
		},
		formHasMainParts(newVal, oldVal) {
			// this.form.canRepairWithoutParts = ""
			if (!newVal || newVal === 3) {
				this.form.mainPartsList = []
			}
			// if ((newVal === 1 || newVal === 2) && (oldVal === "" || oldVal === 3)) {
			//     this.form.mainPartsList.push(JSON.parse(JSON.stringify(this.mainPartStructure)))
			// }
		}
	},
	methods: {
		onMachineNoInput: debounce(function (value) {
			if (value) {
				this.getCsmsInfo(true)
			} else {
				this.warrantyDays = 0
				this.resetCsmsInfo()
			}
		}, 500),
		onSaleDateChanged(value) {
			this.updateWarranty()
		},
		initForm() {
			// 初始化数据
			const userInfoStr = window.sessionStorage.getItem('userInfo')
			if (!userInfoStr) return false

			const userInfo = JSON.parse(userInfoStr)
			if (userInfo) {
				this.form.dealerName = this.form.dealerName.trim() || userInfo.agentName.trim() || ""
			}
		},
		formatPriceString(price) {
			if (price === null || price === undefined) return ""
			if (typeof price === "string" && price.trim() === "") return ""
			try {
				const p = parseInt(price)
				if (isNaN(p)) return price
				let s = p.toLocaleString("zh-CN", {style: 'currency', currency: 'CNY'})
				return `${s}元/次`
			} catch (e) {
				return price
			}
		},
		updateWarranty() {
			if (!this.warrantyDays || this.form.invoiceRequired !== 1 || !this.form.repairInvoice.saleDate) {
				this.form.warrantyEndDate = this._warrantyEndDate
				this.form.warrantyPeriod = this._warrantyPeriod
				return
			}

			const endDate = this.$moment(this.form.repairInvoice.saleDate).add(this.warrantyDays, "days")
			endDate.hour(0).minute(0).second(0).millisecond(0)
			this.form.warrantyEndDate = endDate.format("YYYY-MM-DD")

			const today = this.$moment().hour(0).minute(0).second(0).millisecond(0)
			this.form.warrantyPeriod = endDate.isBefore(today) ? "0" : "1"
		},
		handleAddMainPart() {
			this.form.mainPartsList.push(JSON.parse(JSON.stringify(this.mainPartStructure)))
		},
		handleRemoveMainPart(index) {
			this.form.mainPartsList.splice(index, 1)
		},
		resetCsmsInfo() {
			this.form.productCode = ""
			this.form.modelCode = ""
			this.form.outDate = ""
			this.form.warrantyEndDate = ""
			this.form.warrantyPeriod = ""
			this.form.ppsNo = ""
		},
		getCsmsInfo: debounce(function (setInvoice) {

			// 是否需要将销售日期和发票附件同步为机号关联的数据,
			// 默认第一次恢复表单使用的是暂存数据，机号变更了后再使用机号关联的销售日期和发票附件
			const _setInvoice = !!setInvoice

			if (!this.form.machineNo) return

			let that = this
			that.$request.get(
				"claimantCsmsInfo",
				false,
				{serialNo: this.form.machineNo},
				function (r) {
					if (r.code == "0") {
						if (r?.data?.warrantyLength) {
							try {
								const days = parseInt(r.data.warrantyLength)
								if (!isNaN(days)) {
									that.warrantyDays = days
								} else {
									that.warrantyDays = 0
								}
							} catch {
								that.warrantyDays = 0
							}
						}
						that._warrantyEndDate = r?.data?.warrantyEndDate ? r.data.warrantyEndDate : ""
						that._warrantyPeriod = r?.data?.warrantyPeriod ? r.data.warrantyPeriod : ""
						// 对应属性同步至表单
						for (const attr in r.data) {
							if (that.form.hasOwnProperty(attr)) {
								that.$set(that.form, attr, r.data[attr])
							}
						}

						if (_setInvoice) {
							// 如果机号在系统中有发票相关信息，那么覆盖发票信息，并且上传发票固定为[是]
							if (r.data?.invoiceInfo) {
								// 附件
								that.invoiceFileList = [
									{
										name: that.getUrlFileName(r.data.invoiceInfo.invoiceFile),
										url: r.data.invoiceInfo.invoiceFile,
										status: 'finished', // 文件上传状态，可以是 'uploading', 'finished', 'error'
										response: {} // 服务器响应
									}
								]
								that.form.invoiceRequired = 1
								that.form.repairInvoice.invoiceFile = r.data.invoiceInfo.invoiceFile
								that.form.repairInvoice.saleDate = r.data.invoiceInfo.saleDate
							} else {
								that.form.invoiceRequired = ""
							}
						}
						that.invoiceForceRequired = !!r.data?.invoiceInfo

						that.updateWarranty()
					} else {
						that.resetCsmsInfo()
						that.warrantyDays = 0
						that._warrantyEndDate = ""
						that._warrantyPeriod = ""
						that.invoiceForceRequired = false
						that.$message.error(r.msg)
					}
				},
				function () {
				}
			)
		}, 500),
		getProvinceData() {
			let that = this
			that.$request.get(
				"getDataAddr",
				false,
				{},
				function (r) {
					if (r.code == "0") {
						that.provinceData = r.data
					} else {
						that.$message.error(r.msg)
					}
				},
				function () {
				}
			)
		},
		getFaultDictData() {
			// 获取故障相关的字典数据
			let that = this
			const params = {
				"codes": "authRepFaultCause" +
					",authRepFaultCauseType" +
					",authRepFaultMeasures" +
					",authRepFaultMeasuresType" +
					",authRepFaultPhen" +
					",authRepFaultPhenType" +
					",authRepFaultPosition" +
					",authRepFaultPositionType"
			}
			that.$request.post(
				"getListByCodes",
				false,
				params,
				function (r) {
					if (r.code == "0") {
						that.faultDictData = r.data
					} else {
						that.$message.error(r.msg)
					}
				},
				function () {
				}
			)
		},
		getWarrantyPeriodText(warrantyPeriod) {
			for (const option of this.warrantyPeriodOptions) {
				if (option.id === warrantyPeriod) {
					return option.name
				}
			}
			return ""
		},
		getUrlFileName(url) {
			if (!url) return ""
			return url.substring(url.lastIndexOf("/") + 1)
		},
		getFinishedFile(file) {
			return {
				id: file.id,
				name: file.fileName,
				url: file.fileAddr,
				size: file.fileSize,
				status: 'finished', // 文件上传状态，可以是 'uploading', 'finished', 'error'
				response: {} // 服务器响应
			}
		},
		handleBeforeUpload(file) {
			// 上传前检查
		},
		// 上传附件成功
		handleFileUploaded(file, fileList) {
			this.form.repairInvoice.invoiceFile = file.data.fileAddr
			const f = this.getFinishedFile(file.data)
			this.invoiceFileList = [f]
		},
		handleFileRemove(file) {
			this.form.repairInvoice.invoiceFile = ""
			this.invoiceFileList = []
		},
		handleBeforeAttachFileUpload(file) {
			// 上传前检查
			const limitSize = file.size / 1024 / 1024 <= 2
			if (!limitSize) {
				this.$message.warning('附件大小不能超过2M')
				return false
			}
		},
		// 上传附件成功
		handleAttachFileUploaded(file, fileList) {
			this.form.fileIdList.push(file.data.id)
			const f = this.getFinishedFile(file.data)
			this.attachFileList.push(f)
		},
		handleAttachFileRemove(file) {
			this.form.fileIdList.splice(this.form.fileIdList.indexOf(file.id), 1)
			this.attachFileList.splice(this.attachFileList.indexOf(file), 1)
		},
		getRepairData() {
			if (!this.$route.query.id) return

			let that = this
			that.$request.get(
				"repairRequestInfo",
				true,
				{
					id: this.$route.query.id,
				},
				function (r) {
					if (r.code == "0") {
						that.dataId = r.data.id
						that.restoreFrom(r.data)
						that.getCsmsInfo(false)

                        if (r.data?.hisAudit) {
                            const auditHistory = r.data.hisAudit ?? []
                            if (auditHistory.length > 0) {
                                const latestAudit = auditHistory[auditHistory.length - 1]
                                that.auditStatus = latestAudit.auditStatus
                            }
                        }

					} else {
						that.$message.error(r.msg)
					}
				},
				function () {
				}
			)
		},
		restoreFrom(data) {
			// 遍历设置所有非数组属性
			this.traverseSet(this.form, data)

			// faultList
			if (data.faultList) {
				for (let i in this.form.faultList) {
					if (data.faultList[i]) {
						for (const attr in this.form.faultList[i]) {
							this.$set(this.form.faultList[i], attr, data.faultList[i][attr])
						}
					}
				}
			}

			// mainPartsList
			this.form.mainPartsList = []
			if (data.mainPartsList) {
				for (let i in data.mainPartsList) {
					const mainPart = JSON.parse(JSON.stringify(this.mainPartStructure))
					for (const attr in mainPart) {
						mainPart[attr] = data.mainPartsList[i][attr]
					}
					this.form.mainPartsList.push(mainPart)
				}
			}

			// 附件
			this.invoiceFileList = [
				{
					name: this.getUrlFileName(this.form.repairInvoice.invoiceFile),
					url: this.form.repairInvoice.invoiceFile,
					status: 'finished', // 文件上传状态，可以是 'uploading', 'finished', 'error'
					response: {} // 服务器响应
				}
			]
			const files = data.sysAttachFileList || []
			this.attachFileList = files.map(item => {
				return this.getFinishedFile(item)
			})

			// fileIdList
			this.form.fileIdList = files.map(item => {
				return item.id
			})

			const province = parseInt(this.form.province)
			const city = parseInt(this.form.city)
			const district = parseInt(this.form.district)
			// 省市区
			if (!isNaN(province) && !isNaN(city) && !isNaN(district)) {
				this.address = [province, city, district]
			}
		},
		traverseSet(form, data) {
			// 遍历设置除数组属性的所有值
			for (const attr in form) {
				if (typeof form[attr] === "object") {
					if (!Array.isArray(form[attr])) {
						if (data[attr]) this.traverseSet(form[attr], data[attr])
					}
				} else {
					const value = data[attr]
					if (value === undefined) {
						this.$set(form, attr, "")
					} else {
						this.$set(form, attr, value)
					}
				}
			}
		},
		submit: debounce(function () {
			let that = this
			that.$refs['form'].validate((valid, fields) => {
				if (!valid) {
					that.$message.error("维修信息不完整，请完善信息。")
					return
				}

				if (that.isOutOfWarranty) {
					// 已过保
					that.$message.error('商品已过保')
					return
				}

				if (that.form.repairType === "1") {
					// 如果维修区分 为 建议换货，则需要发票信息
					if (that.form.invoiceRequired !== 1) {
						that.$message.error('换货维修必须上传发票信息')
						return
					}

					const saleDate = that.$moment(that.form.repairInvoice.saleDate).hour(0).minute(0).second(0).millisecond(0)
					const today = that.$moment().hour(0).minute(0).second(0).millisecond(0)
					const exchangeDays = 15
					const exchangeDate = saleDate.add(exchangeDays, "days")
					if (exchangeDate.isBefore(today)) {
						that.$message.error(`允许换货的期限为销售日${exchangeDays}天以内`)
						return
					}
				}

				let data = JSON.parse(JSON.stringify(this.form))
				data = Object.assign(data, {
					isDraft: 0
				})

				let url = 'repairRequestAdd'
				if (that.dataId) {
					data["id"] = that.dataId
					url = 'repairRequestUpdate'
				}

				that.$request.post(
					url,
					true,
					data,
					function (r) {
						if (r.code == "0") {
							that.$router.push({
								name: "repairRequest"
							})
						} else {
							that.$message.error(r.msg)
						}
					},
					function () {
					}
				)
			})
		}, 500),
		saveTemporary: debounce(function () {
			let that = this
			let data = JSON.parse(JSON.stringify(this.form))
			data = Object.assign(data, {
				isDraft: 1
			})

			let url = 'repairRequestAdd'
			if (that.dataId) {
				data["id"] = that.dataId
				url = 'repairRequestUpdate'
			}

			that.$request.post(
				url,
				true,
				data,
				function (r) {
					if (r.code == "0") {
						that.$router.push({
							name: "repairRequest"
						})
					} else {
						that.$message.error(r.msg)
					}
				},
				function () {
				}
			)
		}, 500)
	}
}
</script>

<style scoped lang="scss">
.form-section {
	padding-bottom: 20px;
	border: 1px solid rgb(174, 141, 90);
	border-radius: 8px;

	h3 {
		height: 60px;
		line-height: 60px;
		padding: 0 20px;
	}

	.section-content {
		padding: 0 20px;
	}
}

.section-title {
	color: #999999;
	padding-bottom: 18px;
}

.form-table {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	border: 1px solid rgba(0, 0, 0, 0.1);
	margin-bottom: 20px;

	td {
		border: 1px solid rgba(0, 0, 0, 0.1);
	}

	thead {
		background-color: rgb(236, 242, 240);

		tr {
			text-align: left;

			th {
				padding: 18px 10px;

				&.col-required {
					&:before {
						content: '*';
						color: #F56C6C;
						margin-right: 4px;
					}
				}
			}
		}
	}

	tbody {
		tr {
			td {
				padding: 10px;
				vertical-align: top;
			}
		}
	}
}

.fixed-table {
	th:first-child, td:first-child {
		position: sticky;
		right: 0;
		z-index: 1;
	}

	th {
		&:nth-child(1) {
			width: 40px;
		}

		&:nth-child(2) {
			width: 140px;
		}

		&:nth-child(5) {
			width: 140px;
		}

		&:nth-child(7) {
			width: 100px;
		}

		&:nth-child(8) {
			width: 50px;
		}
	}

	td {
		vertical-align: middle !important;
	}
}

.text-center {
	text-align: center;
}

.notice-text {
	color: rgb(204, 80, 80);
	margin-bottom: 30px;
}

.notice-subtitle {
	font-size: 14px;
	color: #606266;
}

.fixed-wrapper {
	position: fixed;
	right: 12%;
	bottom: 200px;
}

.btn-red {
	color: #E9515E;
	text-decoration: underline;
	cursor: pointer;
}
</style>
